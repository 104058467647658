.container {
    background-color: white;
    padding: 15px 25px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 15px;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);

    @media (max-width: 550px) {
        padding: 10px 20px;
    }

    .icon {
        width: 40px;
        height: 40px;

        @media (max-width: 550px) {
            width: 30px;
            height: 30px;
        }
    }

    .info_container {

        .flex_container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;

            @media (max-width: 550px) {
                gap: 15px;
            }

            .time {
                color: gray;
                font-size: 12px;
            }
        }

        .title {
            font-weight: 600;
            font-size: 17px;
            white-space: nowrap;


            @media (max-width: 550px) {
                font-size: 15px;
            }
        }

        .desc {
            max-width: 350px;
            font-size: 13px;
            color: gray;

            @media (max-width: 550px) {
                font-size: 12px;
            }
        }
    }

    .status {
        background-color: rgba(0, 0, 0, 0.386);
        color: white;
        padding: 5px 10px;
        font-size: 11px;
        white-space: nowrap;
        border-radius: 16px;

        @media (max-width: 550px) {
            font-size: 10px;
        }
    }
}