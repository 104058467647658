.container {
    background-color: white;
    padding: 10px 15px;
    border-radius: 16px;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);

    @media (max-width: 500px) {
        padding: 10px 10px;
    }

    &.redFlag{
        border: 1px solid red;
        filter: drop-shadow(0 0 2px red);
    }

    .other_transform{
        display: none;

        @media (max-width: 700px) {
            display: block;
            color: #afafaf;
            font-size: 13px;
        }
    }

    .left_container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .music_info {
        width: 30%;
        display: flex;
        align-items: center;
        gap: 15px;

        @media (max-width: 700px) {
            width: 100%;
        }

        .flag{
            @media (max-width: 700px) {
                display: none;
             }
             color: red;
             filter: drop-shadow(0 0 2px red);
        }

        .icon{
            @media (max-width: 700px) {
               display: none;
            } 
        }

        .title {
            font-weight: 600;

            @media (max-width: 700px) {
                font-size: 15px;
            }
        }

        .video_info_container{
            display: flex;
            align-items: center;
            gap: 10px;

            button{
                margin-top: 5px;
                display: none;
                background-color: lightgray;
                font-size: 13px;
                border-radius: 16px;
                padding: 3px 10px;
                color: white;
                background-color: rgb(254, 63, 63);
                transition-duration: 300ms;

                &:hover{
                    background-color: #FFA500;
                    color: black;
                }

                @media (max-width: 700px) {
                    display: block;
                }
            }
        }

        .other {
            color: #afafaf;
            
            @media (max-width: 700px) {
                color: #afafaf;
                font-size: 13px;
            }
        }

    }

    .data_container {
        margin-left: 25px;
        align-items: center;
        display: flex;
        gap: 25px;
        width: 100%;

        @media (max-width: 700px) {
            display: none;
        }

        .views_container {
            display: flex;
            align-items: center;
            width: 30%;
            gap: 5px;

            p {
                white-space: nowrap;

                span {
                    color: #afafaf;
                    font-weight: 400;
                }

                font-weight: 600;
            }
        }

        .videos_container {
            display: flex;
            align-items: center;
            width: 20%;
            gap: 5px;
            cursor: pointer;

            p {
                white-space: nowrap;
                color: #afafaf;
                font-weight: 400;

                &:hover {
                    color: black;
                }
            }

            .btn_add {
                margin-left: 10px;
                width: fit-content;
                height: fit-content;
                transition-duration: 300ms;

                .icon {
                    color: lightgray;
                    width: 35px;
                    height: 35px;
                    transition-duration: 300ms;
                    color: rgb(254, 63, 63);

                    &:hover {
                        transform: rotateX(200deg);
                        color: #FFA500;
                        width: 35px;
                        height: 35px;
                    }
                }
            }
        }
    }

    .icon {
        color: #FFA500;
    }

    .sound_container {
        input {
            border-radius: 2px;
            accent-color: #FFA500;
        }
    }

    .btn_container {
        display: flex;
        align-items: center;
        gap: 15px;

        .btn {
            background-color: #FFA500;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            color: black;
            padding: 10px;
            cursor: pointer;
            transition-duration: 300ms;

            &:hover {
                background-color: black;
                color: white;
            }
        }

        .btn_download {
            background-color: black;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            color: #FFA500;
            padding: 10px;
            cursor: pointer;
            transition-duration: 300ms;

            &:hover {
                background-color: #FFA500;
                color: black;
            }
        }
    
        .options{
            cursor: pointer;

        }
    }

    .get_paymen_btn {
        margin-top: 15px;
        margin-left: auto;
        font-size: 10px;
        border-radius: 8px;
        border: 1px solid #ffa600aa !important;
        padding: 5px;
        display: flex;
        color: gray;
        height: fit-content;
    
        &:hover{
            background-color: #ffa600aa;
            color: white !important;
        }
    }
}