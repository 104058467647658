.container {
    width: fit-content;
    background-color: white;
    height: fit-content;
    border-radius: 16px;
    max-width: 300px;
    overflow: hidden;
    cursor: pointer;
     -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);

    .preview{
        display: flex;
        align-items: center;

        img{
            margin-top: -10px;
            width: 100%;
        }
    }

    .title{
        font-weight: 700;
    }

    .info_container{
        padding: 10px 25px;
        background-color: white;
    }

    .post_info_container{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .date{
            color: rgb(172, 172, 172);
            font-size: 10px;
        }

        .likes_count{
            display: flex;
            gap: 5px;
            align-items: center;
            color: rgb(172, 172, 172);
            font-size: 14px;

            .count{
                font-weight: 600;
            }
        }
    }

    .post_section{
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .section{
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 8px;
            width: fit-content;
            background-color: #ffa600;
        }
    }
}