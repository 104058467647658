.header {
    width: 100%;
    height: fit-content;
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;

    .close_icon {
        position: absolute;
        width: 35px;
        height: 35px;
        right: 10px;
        top: 10px;
    }

    .withdraw_btn {
        font-size: 13px;
        padding: 5px 10px;
        border-radius: 8px;
        cursor: pointer;
        background-color: rgb(211, 211, 211, 0.5);
        transition-duration: 300ms;

        &:hover {
            background-color: #ffa6007f;
        }
    }

    .open_nav {
        transition-duration: 300ms;
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        padding: 15px;
        height: 100vh;
        z-index: 10;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
        background: rgb(255, 255, 255, 0.6);
        backdrop-filter: blur(5px);
    }

    .top {
        display: flex;

        p {
            font-size: 25px;
            font-weight: 600;
        }
    }

    .navigations {
        margin-top: 25px;

        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;

            li {
                background-color: #ffa60088;
                display: flex;
                gap: 10px;
                padding: 10px 15px;
                border-radius: 16px;
                font-size: 17px;

                &.blog {
                    background-color: #FFA500;
                }

                &.close {
                    background-color: rgb(255, 89, 89);
                    color: white;
                }

                &.settings {
                    background-color: lightgray;
                    color: black;
                }
            }
        }

        .other_container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 25px;
        }
    }

    .close_nav {
        transition-duration: 300ms;
        position: fixed;
        top: 0;
        left: -100%;
        width: 250px;
        height: 100vh;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
        background: rgba(255, 255, 255, 0.1);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
    }



    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: lightgray;
        bottom: 0;
        left: 0;
    }

    @media (max-width: 710px) {
        padding: 0px;
    }

    .container {
        align-items: center;
        display: flex;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;

        .left_container {
            display: flex;
            align-items: center;
            gap: 15px;

            .menu_icon {
                width: 30px;
                height: 30px;
                display: none;

                @media (max-width: 710px) {
                    display: block;
                }
            }

            .balance_container {
                display: flex;
                align-items: center;
                gap: 15px;

                .title {
                    font-weight: 500;
                    color: gray;
                }

                .balance {
                    color: black;
                    font-weight: 600;
                }
            }
        }

        .user_events_container {
            display: flex;
            align-items: center;
            gap: 50px;
        }

        .btn_container {
            display: flex;
            align-items: center;
            gap: 15px;

            @media (max-width: 450px) {
                display: none;
            }

            .btn {
                width: 50px;
                height: 50px;
                padding: 10px;
                border-radius: 100%;
                background-color: rgb(211, 211, 211, 0.5);
                color: gray;
                -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                cursor: pointer;
                transition-duration: 300ms;

                &:hover {
                    background-color: #FFA500;
                    color: black;
                }
            }
        }

        .flex_container {
            gap: 25px;
            display: flex;
            align-items: center;
        }

        .nav_container {
            display: flex;
            align-items: center;

            ul {
                display: flex;
                align-items: center;
                gap: 25px;

                li {
                    list-style-type: none;
                    font-size: 17px;
                    font-weight: 600;
                    transition-duration: 200ms;

                    &:hover {
                        cursor: pointer;
                        background-color: black;
                        padding: 10px 15px;
                        color: white;
                        border-radius: 20px;
                    }
                }
            }
        }

        .info_user_container {
            width: fit-content;
            transition-duration: 300ms;

            .user_data_container {
                padding: 10px;
                margin-top: 10px;
                border-radius: 8px;
                background-color: #dfeffd;
                position: absolute;
                width: 225px;
                height: 0px;
                overflow: hidden;
                display: none;
                -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);

                &.open {
                    display: block;
                    height: 100px;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    li {
                        list-style-type: none;
                    }
                }

            }
        }

        .user_container {
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: rgb(211, 211, 211, 0.5);
            padding: 10px;
            border-radius: 16px;
            -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            gap: 25px;

            @media (max-width: 450px) {
                background-color: transparent;
                border-radius: 0;
                box-shadow: none;
            }

            .arrow_open {
                transform: rotate(180deg);
                transition-duration: 300ms;
            }

            .arrow_close {
                transform: rotate(0deg);
                transition-duration: 300ms;
            }

            .info_container {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .nick {
                font-weight: 600;
                font-size: 20px;
            }

            .desc {
                font-size: 14px;
                color: gray;
            }
        }
    }
}