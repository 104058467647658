
.ref_container {
    background-color: #dfeffd;
    width: fit-content;
    height: fit-content;
    padding: 15px 25px;
    -webkit-box-shadow: 4px 4px 8px -5px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -5px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -5px rgba(34, 60, 80, 0.2);
    border-radius: 8px;

    .profit_container{
        background-color: #ffa600aa;
        width: fit-content;
        height: fit-content;
        padding: 5px 10px;
        margin-top: 15px;
        margin-left: auto;
        border-radius: 8px;
        font-size: 11px;
        font-weight: 600;
    }

    .user_icon {
        width: 30px;
        height: 30px;
    }

    .user_info_container{

        .time{
            color: gray;
            font-size: 11px;
        }

        .nick{
            font-weight: 700;
        }

        .other{
            color: gray;
            font-size: 13px;

            span{
                color: black;
                font-weight: 600;
            }
        }
    
        .data_container{ 
            display: flex;
            align-items: center;
            gap: 10px; 
            height: fit-content;
        
            .divider{
                height: 30px;
                width: 2px;
                background-color: lightgray;
            }
        }
    }
}