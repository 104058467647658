.container{
    max-width: 40%;
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media (max-width: 710px) {
        max-width: 100%;
    }
}