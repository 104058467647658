.main_container {
    display: flex;
    min-height: 100vh;
    background-color: #dfeffd;
    overflow-y: hidden;
    min-height: 100vh;

    .container {
        width: 100%;
        max-width: 100%;
        background-color: #eff9fd;
        padding-left: 25px;
        padding-right: 25px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        -webkit-box-shadow: -4px 1px 8px -4px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: -4px 1px 8px -4px rgba(34, 60, 80, 0.2);
        box-shadow: -4px 1px 8px -4px rgba(34, 60, 80, 0.2);
    
        .telegram{
            height: 50px;
            width: 50px;
            padding: 10px;
            border-radius: 50%;
            color: #dfeffd;
            position: absolute;
            right: 15px;
            top: 80vh;
            background-color: lightgray;
            transition-duration: 300ms;
            cursor: pointer;

            &:hover{
                background-color: #FFA500;
            }
        }
    }
}