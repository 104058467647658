.container {
    width: 360px;
    height: 220px;
    border-radius: 24px;
    position: relative;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);

    .bg {
        position: absolute;
        z-index: 0;
    }

    .close_icon{
        position: absolute;
        z-index: 1;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
        font-size: 10px;
        padding: 5px 10px;
        height: fit-content;
        border-radius: 16px;
        background-color: lightgray;
        color: gray;
        border-color: lightgray !important;
    
        &:hover{
            background-color: orange;
            color: white !important;
        }
    }

    .data_container {
        position: absolute;
        z-index: 1;
        color: lightgray;
        padding: 30px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: space-between;

            img {
                width: 50px;
            }
        }

        .card_number {
            font-size: 20px;
        }

        .name_on_card {
            margin-top: 20px;
        }
    }
}