.container {
    width: 100%;
    padding: 15px 25px;
    border-radius: 16px;
    background-color: white;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    position: relative;

    @media (max-width: 550px) {
        padding: 10px 15px;
    }

    .last_time_save {
        margin-top: 5px;
        color: lightgray;
        font-size: 14px;
        font-weight: 400;

        @media (max-width: 550px) {
            font-size: 11px;
        }
    }

    .container_container {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .data_container {
        width: 100%;
        display: flex;
        align-items: center;

        .other {
            color: gray;
            font-size: 15px;

            @media (max-width: 550px) {
                font-size: 13px;
            }
        }

        .track_info {
            max-width: 35%;
            width: 100%;

            .title {
                font-weight: 600;
                font-size: 17px;

                @media (max-width: 600px) {
                    font-size: 15px;
                }


                @media (max-width: 550px) {
                    font-size: 13px;
                }
            }
        }

        .view_coomerce_count {
            width: 25%;
            text-align: center;
            font-size: 14px;
            color: rgb(0, 202, 0);

            @media (max-width: 550px) {
                font-size: 11px;
            }
        }

        .view_count {
            color: gray;
            width: 25%;
            text-align: center;
            font-size: 14px;

            @media (max-width: 550px) {
                font-size: 11px;
            }
        }
    }

    .save_btn {
        width: 40px;
        background-color: #dfeffd;
        padding: 7px;
        border-radius: 100%;
        height: 40px;
        cursor: pointer;
    }
}