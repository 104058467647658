.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);

    .modal {
        padding: 15px 35px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        position: relative;
        background: white;
        border-radius: 16px;
        backdrop-filter: blur(5px);
        border: 2px solid #ffa60074;

        .close{
            position: absolute;
            right: 10px;
            cursor: pointer;
        }

        .withdraw_input_container{
            display: flex;
            align-items: center;
            gap: 15px;

            p{
                font-size: 13px;
                font-weight: 600;
            }
        }
    
        .method{
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            background-color: rgb(211, 211, 211, 0.5);
            padding: 10px 15px;
            border-radius: 16px;
            transition-duration: 300ms;
            
            &.selected{
                border: 2px solid #ffa600;
                background-color: #ffa60074;
            }

            &:hover{
                background-color: #ffa60074;
            }

            img{
                max-width: 45px;
            }
        
            .invoice{
                font-size: 10px;
                color: gray;
            }
        }

        button{
            background-color: rgb(211, 211, 211, 0.5);
            border-color: rgb(211, 211, 211, 0.5) !important;
            margin-top: 15px;
            border-radius: 16px;
            color: gray;
        
            &:hover{
                background-color: #ffa60074;
                color: black !important;
            }
        }
    }
}