.container {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px 15px;
    border-radius: 16px;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    justify-content: space-between;

    .preview{
        border-radius: 16px;
    }

    .section_left{
        display: flex;
        align-items: center;
    
        .data{
            margin-left: 20px;
    
            .title{
                font-weight: 600;
                font-size: 20px;
            }
    
            .data_container{
                margin-top: 10px;
                display: flex;
                align-items: center;
                gap: 50px;
            
                .count_container{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
            }
    
            .other{
                color: #afafaf;
    
                span{
                    color: black;
                    font-weight: 600;
                }
            }
        
            .info{
                display: flex;
                align-items: center;
                gap: 10px;
    
                .icon{
                    color: #FFA500;
                }
            }
        }
    }

    .btns_cotnainer{
        display: flex;
        align-items: center;
        gap: 15px;

        .btn_save{
            background-color: #FFA500;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            color: black;
            padding: 10px;
            cursor: pointer;
            transition-duration: 300ms;

            &:hover{
                background-color: black;
                color: white;
            }
        }
    
        .btn_delete{
            background-color: red;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            color: white;
            padding: 10px;
            cursor: pointer;
            transition-duration: 300ms;
        
            &:hover{
                background-color: black;
                color: #FFA500;
            }
        }
    }
}