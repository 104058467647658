.container{
    margin-top: 25px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .header{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img{
            margin: 25px 0px;
            max-height: 300px;
            border-radius: 16px;
            max-width: 100%;
        }

        h1{
            width: 100%;
            text-align: start;
            color: gray;
        }
    }

    .text_body{
        max-width: 800px;
    }
}