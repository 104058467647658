.container{
    margin-top: 25px;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .btns_container{
            .btn{
                cursor: pointer;
                padding: 10px 15px;
                border-radius: 16px;
                background-color: #ffa60074;
            }
        }
    }

    .finance_container{
        gap: 25px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        
        @media (max-width: 710px) {
            flex-direction: column;
        }
    }
}