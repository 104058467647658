.container {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1000px) {
        flex-direction: column;
    }

    .referral_container {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 25px;
    }

    .referrer_container {
        width: fit-content;

        .info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 25px;
            padding: 25px;
            border-radius: 16px;
            background-color: white;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        }

        h4{
            color: gray;

            span{
                color: black;
            }
        }

        .referralCode{
            font-weight: 800;
            font-style: italic;
            cursor: pointer;
        }
    }
}