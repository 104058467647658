.container {
    
    .sections_container {
        display: flex;
        gap: 30px;
        height: 100%;

        .user_data_container {
            width: 100%;
        }
    }
}