.container{
    margin-top: 25px;
    width: fit-content;

    .items_container {
        width: min-content;
        display: grid;
        row-gap: 15px;
        grid-template-columns: 1fr 1fr;
        column-gap: 25px;
    }
}