.container {
    width: 35px;
    background-color: #dfeffd;        
    padding: 5px;
    border-radius: 100%;
    height: 35px;
    cursor: pointer;
    transition-duration: 300ms;
    
    .isLoading {
        animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(-360deg);
        }
    }
}