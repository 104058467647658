.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);

    .modal {
        padding: 15px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        position: relative;
        background: white;
        border-radius: 16px;
        backdrop-filter: blur(5px);
        border: 2px solid #ffa60074;

        .title{
            font-size: 20px;
            font-weight: 600;
        }

        .close_modal_container{
            position: absolute;
            right: 15px;

            .icon{
                cursor: pointer;
                width: 25px;
                height: 25px;
            }
        }

        .inputs_container {
            position: relative;

            .select_method_container {
                border: 1px solid #ffa600;
                padding: 10px 15px;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 25px;

                .arrow {
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                
                    &.open {
                        transform: rotate(180deg);
                        transition-duration: 300ms;
                    }
        
                    &.close {
                        transform: rotate(0deg);
                        transition-duration: 300ms;
                    }
                }
            }

            .dropdown_list {
                margin-top: 5px;
                position: absolute;
                height: fit-content;
                width: 100%;
                height: 0px;
                display: none;
                border-radius: 16px;
                background-color: white;
                -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
                overflow: hidden;
                z-index: 1;


                &.open{
                    height: fit-content;
                    display: block;
                }

                .item{
                    padding: 10px 15px;
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    transition-duration: 300ms;

                    .payment_icon{
                        width: 50px;
                    }
                
                    .name{
                        font-weight: 600;
                    }
                
                    .desc{
                        font-size: 10px;
                    }
                
                
                    &:hover{
                        background-color: #ffa6001d;
                        cursor: pointer;
                    }
                }
            }
        }
    
        .select_container{
            display: flex;
            align-items: center;
            gap: 10px;

            img{
                width: 30px;
            }
        
            p{
                font-size: 15px;
                color: gray;

                span{
                    color: black;
                }
            }
        }

        .invoice_container{
            display: flex;
            flex-direction: column;
            gap: 15px;

            .input_container{
                p{
                    span{
                        color: red;
                    }
                }

                input{
                    width: 100%;
                    border: 1px lightgray solid;
                    padding: 10px 15px;
                    border-radius: 16px;
                    transition-duration: 300ms;

                    &:focus{
                        border-color: #ffa600;
                    }
                }
            }
        }
    
        button{
            font-weight: 600;
            border-radius: 16px;
            padding: 7px;
            height: fit-content;
            color: gray;
            background-color: lightgray;
        
            &:hover{
                background-color: #ffa600;
                color: black !important; 
                border-color: #ffa600 !important;
            }
        }
    }
}