.container{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);

    .modal{
        padding: 15px 35px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        position: relative;
        background: white;
        border-radius: 16px;
        backdrop-filter: blur(5px);
        border: 2px solid #ffa60074;

        .close{
            position: absolute;
            right: 10px;
            cursor: pointer;
        }

        .title{
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }
    
        .inputs_container{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .item{
                display: flex;
                flex-direction: column;
                gap: 5px;

                input{
                    padding: 10px 20px;
                    border: 1px solid lightgray;
                    border-radius: 16px;
                }
            }
        }
    
        button{
            margin-top: 25px;
            height: fit-content;
            background-color: lightgray;

            &:hover{
                color: black !important;
                font-weight: 600;
                background-color: orange !important;
                border-radius: 16px;
                border-color: orange !important;
            }
        }
    }
}