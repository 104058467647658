.container {
    max-width: 1240px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    position: relative;

    .header {
        margin-top: 25px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        gap: 15px;
        margin: 15px;
        align-items: center;
        display: flex;

        .text_container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            h2 {
                span {
                    color: gray;
                }
            
                @media (max-width: 500px) {
                    font-size: 17px;
                }
            }

            p {
                font-size: 17px;
                font-weight: 600;
                color: gray;

                @media (max-width: 500px) {
                    font-size: 13px;
                }
            }
        }
    }

    .info_container {
        position: relative;
        // padding: 25px 50px;
        border-radius: 16px;
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 35px;
        background-color: lightgray;
        box-shadow: 16px 14px 20px #0000008c;
        border-radius: 10px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            width: 250%;
            height: 250%;
            background: conic-gradient(
                #ffa600 20deg,
                transparent 120deg
                );
            animation: rotate 10s linear infinite;

            @keyframes rotate {
                0%{
                    transform: rotate(0deg);
                }
                100%{
                    transform: rotate(-360deg);
                }
            }
        }

        &::after{
            content: '';
            width: stretch;
            height: stretch;
            margin: 10px;
            background: white;
            position: absolute;
            border-radius: 10px;
            display: flex;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            // box-shadow: inset 10px 10px 10px #00000061;
        }

        .data_container {
            z-index: 10;
            padding: 10px;
            margin: 30px 60px;

            @media (max-width: 650px) {
                margin: 20px 40px;
            }

            @media (max-width: 500px) {
                margin: 15px 25px;
            }

            .desc {
                font-weight: 600;
                text-align: center;
                font-size: 20px;
                z-index: 10;
            }

            .inputs_container {
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                gap: 25px;

                .item {
                    width: 300px;
                    font-weight: 400;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .title {
                        span {
                            color: red;
                        }
                    }

                    .title_phone {
                        span {
                            color: gray;
                        }
                    }

                    input {
                        padding: 10px 15px;
                        border-radius: 16px;
                        -webkit-box-shadow: 4px 4px 8px -5px rgba(34, 60, 80, 0.2);
                        -moz-box-shadow: 4px 4px 8px -5px rgba(34, 60, 80, 0.2);
                        box-shadow: 4px 4px 8px -5px rgba(34, 60, 80, 0.2);
                        transition-duration: 300ms;
                        border: 2px solid lightgray;

                        &:hover{
                            border-color: #ffa600;
                        }
                    }
                }
            }

            button {
                cursor: pointer;
                margin-top: 40px;
                height: fit-content;
                width: 100%;
                padding: 10px 15px;
                border-radius: 16px;
                background-color: lightgray;

                &:hover{
                    background: #ffa600 !important;
                    border-color: #ffa600 !important;
                    color: black !important;
                    font-weight: 600;
                }
            }
        }
    }
}