.container {
    margin-top: 25px;
    display: flex;
    gap: 25px;
    min-height: 100%;

    @media (max-width: 1100px) {
        flex-direction: column;
    }

    .border {
        min-height: 100vh;
        border-radius: 10px;
        border: #e6e6e6 2px solid;

        @media (max-width: 1100px) {
            flex-direction: column;
            min-height: 1px;
        }
    
    }
}