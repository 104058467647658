.container {
    width: 50%;
    height: 100vh;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1100px) {
        width: 100%;
        height: fit-content;
    }

    h1{
        @media (max-width: 500px) {
            font-size: 25px;
        }
    }

    .statics_container {
        margin-top: 15px;
        display: grid;
        row-gap: 15px;
        column-gap: 25px;
        width: fit-content;
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 500px) {
            column-gap: 10px;
        }
    }

    .items_container {
        margin-top: 25px;

        h2 {
            color: gray;

            @media (max-width: 500px) {
                font-size: 20px;
            }
        }

        .header {
            width: unset;
            align-items: center;
            display: flex;
            justify-content: space-between;
            position: relative;
            
            &::before {
                bottom: -10px;
                position: absolute;
                content: '';
                height: 1px;
                background-color: #e6e6e6;
                width: 100%;
            }

            .count {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .items {
            gap: 15px;
            margin: 25px;
            display: flex;
            flex-direction: column;

            @media (max-width: 550px) {
                margin: 25px 0px;
            }
        }
    }
}