.container {
    border-radius: 16px;
    background-color: #dfeffd;
    max-width: 200px;
    min-width: fit-content;
    padding: 10px 15px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-box-shadow: 13px 12px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 13px 12px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 13px 12px 8px 0px rgba(34, 60, 80, 0.2);


    @media (max-width: 500px) {
        padding: 5px 10px;
    }

    .quest_btn {
        background: #eff9fd;
        padding: 5px;
        width: 25px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        cursor: pointer;
        right: 10px;
        height: 25px;
    }

    .header {
        margin-bottom: 25px;

        p {
            color: gray;

            @media (max-width: 500px) {
                font-size: 13px;
            }
        }
    }

    .view_count {
        font-weight: 600;
        font-size: 25px;
        z-index: 1;

        @media (max-width: 500px) {
            font-size: 20px;
        }
    }

    .test {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        right: -10px;
        bottom: -15px;
        background-color: rgba(128, 128, 128, 0.484);
        //filter: drop-shadow(0 0 10px black);
    }
}