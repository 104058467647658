.container {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    max-height: 100vh;
    position: relative;

    .telegram{
        height: 50px;
        width: 50px;
        padding: 10px;
        border-radius: 50%;
        color: #dfeffd;
        position: absolute;
        right: 15px;
        top: 80vh;
        background-color: #FFA500;
        transition-duration: 300ms;
        cursor: pointer;
        animation: wiggle 2s linear infinite;

        &:hover{
            background-color: #825500;
        }
    
        @keyframes wiggle {
            0%, 7% {
              transform: rotateZ(0);
            }
            15% {
              transform: rotateZ(-15deg);
            }
            20% {
              transform: rotateZ(10deg);
            }
            25% {
              transform: rotateZ(-10deg);
            }
            30% {
              transform: rotateZ(6deg);
            }
            35% {
              transform: rotateZ(-4deg);
            }
            40%, 100% {
              transform: rotateZ(0);
            }
          }
    }

    .scroll_text{
        z-index: 2;
        position: absolute;
        display: flex;
        box-shadow: 0 5px 15px rgba(0,0,0, .1);
        background: white;
        transform: rotate(30deg) translateY(350px) translateX(0px);

        div{
            color: #000;
            font-size: 4em;
            white-space: nowrap;
            text-transform: uppercase;
            font-weight: 900;
            animation: animate 30s linear infinite;

            span{
                -webkit-text-stroke: 2px #000;
                color: transparent;
            }
        }

        @keyframes animate {
            0% {
                transform: translateX(300px);
            } 100%{ transform: translateX(0%)}
        }
    }
    .scroll_text_2{
        z-index: 2;
        position: absolute;
        display: flex;
        box-shadow: 0 5px 15px rgba(0,0,0, .1);
        background: white;
        transform: rotate(50deg) translateY(0px) translateX(0px);

        div{
            color: #000;
            font-size: 4em;
            white-space: nowrap;
            text-transform: uppercase;
            font-weight: 900;
            animation: animate 25s linear infinite;

            span{
                -webkit-text-stroke: 2px #000;
                color: transparent;
            }
        }

        @keyframes animate {
            0% {
                transform: translateX(0%);
            } 100%{ transform: translateX(-100%)}
        }
    }

    .form {
        width: fit-content;
        padding: 25px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        margin-right: auto;
        margin-left: auto;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);

        h1{
            color: black;
            //filter: drop-shadow(7px -6px 3px black);
        }

        .logo {
            width: 200px;
            height: 50px;
            background-color: black;
        }

        .inputs_container {
            margin-top: 50px;
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 10px;

            .input_container {
                display: flex;
                border: 2px solid lightgrey;
                gap: 10px;
                padding: 10px;
                border-radius: 16px;
                align-items: center;
                transition-duration: 300ms;

                input {
                    width: 100%;
                    font-size: 17px;
                }

                &:hover {
                    border-radius: 8px;
                    border-color: #FFA500;
                }
            }
        }

        .checkbox_container {
            margin-top: 15px;
            max-width: 400px;
            display: flex;
            gap: 15px;
            font-size: 14px;

            .txt_desc{
                color: rgb(128, 128, 128, 0.7);

                span{
                    text-decoration: underline;
                    cursor: pointer;
                    transition-duration: 300ms;

                    &:hover{
                        color: black;
                    }
                }
            }
        }


        button {
            margin-top: 25px;
            background-color: lightgray;
            width: 250px;
            padding: 5px;
            height: fit-content;
            cursor: pointer;
            font-size: 17px;
            border-radius: 16px;
            transition-duration: 300ms;
            color: white !important;
            border-color: transparent !important;
            -webkit-box-shadow: 4px 3px 8px -3px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 3px 8px -3px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 3px 8px -3px rgba(34, 60, 80, 0.2);

            &:enabled{
                background-color: #FFA500;
            }

        }
    }
}
