.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);

    .modal_container {
        padding: 25px 25px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        display: flex;
        max-width: 40%;
        align-items: center;
        gap: 15px;
        background: white;
        border-radius: 16px;
        backdrop-filter: blur(5px);
        border: 2px solid #ffa60074;

        @media (max-width: 1000px) {
            max-width: 70%;
        }

        @media (max-width: 700px) {
            max-width: 90%;
        }

        .icon {
            width: 50px;
            height: 50px;
            color: #ffa600;
        }

        .desc_container {
            position: relative;

            &::after {
                top: 0;
                left: 0;
                position: absolute;
                content: '';
                width: 3px;
                background-color: #ffa600;
                border-radius: 10px;
                height: 100%;
            }

            p {
                margin-left: 10px;
                color: gray;
            }
        }
    
        .track_info{
            display: flex;
            background-color: rgb(241, 241, 241);
            padding: 10px 20px;
            border-radius: 16px;
            gap: 15px;
            cursor: pointer;
            align-items: center;
        }

        .flex_container{
            display: flex;
            gap: 15px;
            align-items: center;
        
            button{
                border-radius: 16px;
                color: gray;
                cursor: pointer;
                height: fit-content;
                transition-duration: 300ms;
                background-color:rgb(241, 241, 241);
                border-color: transparent !important;

                &:hover{
                    background-color: #ffa600;
                    color: black;
                }
            }
        }
    }
}