.container {
    width: 100%;
    background-color: #FFA500;
    border-radius: 16px;
    align-items: center;
    padding: 15px 25px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    background-color: #ffa60088;
    filter: drop-shadow(0 0 10px #ffa60088);

    @media (max-width: 600px) {
        font-size: 15px;
    }

    @media (max-width: 450px) {
        font-size: 12px;
        padding: 10px 15px;
    }
    
    .icon{
        min-width: 25px;
        min-height: 25px;
    }

    span {
        text-decoration: underline;
        cursor: pointer;
    }
}