.container {
    background-color: white;
    padding: 15px 25px;
    border-radius: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);

    @media (max-width: 500px) {
        padding: 10px 15px;
    }

    .left_container {
        display: flex;
        gap: 10px;
        align-items: center;

        .icon {
            width: 30px;
            height: 30px;

            &.receiving {
                color: lightgray;
            }
        }

        .title {
            font-weight: 600;
            font-size: 17px;
            white-space: nowrap;
            color: black;

            @media (max-width: 500px) {
                font-size: 15px;
            }
        }

        .desc {
            max-width: 350px;
            font-size: 13px;
            color: gray;
        }
    }

    .right_container {
        .amount {
            font-weight: 600;
            text-align: end;
            font-size: 19px;
            color: rgb(105, 240, 105);
        }

        .date {
            max-width: 350px;
            font-size: 11px;
            color: gray;
            text-align: end;
        }
    }
}