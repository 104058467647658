.track_info {
    margin-top: 25px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 700px) {
            flex-direction: column;
            align-items: normal;
        }

        .track_count {
            font-size: 30px;
            font-weight: 700;

            span {
                color: lightsteelblue;
            }
        
            @media (max-width: 600px) {
                font-size: 20px;
            }
        }

        .right_container {
            display: flex;
            align-items: center;
            gap: 25px;
            margin-top: 15px;

            @media (max-width: 550px) {
                flex-direction: column;
                align-items: normal;
                gap: 10px;
            }

            .search_track {
                display: flex;
                align-items: center;
                background-color: white;
                padding: 10px;
                border-radius: 16px;
                gap: 10px;
                -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);

                .icon {
                    padding: 5px;
                    background-color: #b0c4de;
                    border-radius: 8px;
                }

                input {
                    font-weight: 600;
                    font-size: 15px;
                }
            }

            .add_track_btn {
                display: flex;
                align-items: center;
                font-size: 15px;
                padding: 10px 15px;
                border-radius: 8px;
                font-weight: 600;
                gap: 5px;
                background-color: #ffa60094;
                -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                cursor: pointer;
                transition-duration: 300ms;
            
                &:hover{
                    background-color: #ffa600;
                }
            
                @media (max-width: 550px) {
                    font-size: 14px;
                    padding: 7px 10px;
                    width: fit-content;
                    margin-left: auto;
                }
            }
        }
    }

    .tracks_container {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}